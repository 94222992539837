<template>
  <Loader :value="promises.fetch">
    <div class="residential-page">
      <Blocks :blocks="config" :custom-components="customComponents" />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ResidentialComponents from '@/components/Residential'
import CustomComponents from '@/components/Custom'
import Config from '../config/residential'
import { Blocks } from '@kvass/pagebuilder'
import ResidentialsList from '@/components/ResidentialsList'
import { animate, inView } from 'motion'
import BrowserApiMixin from '@/mixins/browser-api'
import FlatfinderCustom from '@/components/FlatfinderCustom'
import ReadMore from '@/components/ReadMore'

export default {
  mixins: [BrowserApiMixin('residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val)
          .then(data => {
            if (!data) return this.$router.push({ name: 'notfound' })
          })
          .then(() => this.$nextTick(() => this.initAnimation()))
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Residential', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ResidentialComponents,
        ResidentialsList,
        FlatfinderCustom,
        ReadMore,
      }
    },
  },
  methods: {
    ...mapActions('Residential', ['fetch']),
    async initAnimation() {
      inView(
        '.section-header',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView('.openhouse', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })

      inView('.roles', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { delay: 0, duration: 0.8 })
      })
    },
  },
  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  mounted() {
    this.promises.fetch
  },

  components: {
    ...ResidentialComponents,
    Blocks,
  },
}
</script>

<style lang="scss">
.residential-page {
  min-height: 100vh;
  & > .kpb-blocks {
    @for $i from 1 through 15 {
      & > *:nth-child(#{$i}) {
        order: $i * 10;
      }
    }
  }

  /* START OF FOR ANIMATIONS */

  .roles-layout {
    overflow: hidden;
  }
  .openhouse {
    opacity: 0;
    transform: translateX(-100px);
  }
  .roles {
    opacity: 0;
    transform: translateX(100px);
  }

  /* END OF FOR ANIMATIONS */

  .roles-layout {
    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  }

  .residential__cover {
    $height: calc(50vh + #{$nav-height});

    position: relative;
    height: $height;
    width: 100%;

    img {
      object-position: center;
    }
    iframe {
      min-height: 100vh;
    }

    display: flex;
    flex-direction: column;
    @include respond-below('phone') {
      iframe {
        min-height: 100vh;
      }
    }
  }

  .residential__description {
    @include respond-below('tablet') {
      column-count: 1;
    }

    column-count: 2;
    column-gap: 4rem;

    h2 {
      text-transform: uppercase;
      column-span: all;
    }
    p:first-of-type {
      @include header-font(1.25rem);
      column-span: all;
      margin-bottom: 2rem;
      @include respond-below('tablet') {
        margin-bottom: 1rem;
      }
    }

    p:not(:nth-of-type(1)) {
      margin-top: 0;
    }
  }

  .residential-cta-section {
    color: var(--secondary);

    .kpb-button {
      min-width: 180px;
    }
  }

  .kvass-image-text__item {
    opacity: 0;
  }

  .section-floorplan {
    .kpb-photoswipe__item {
      background: linear-gradient(
        90deg,
        #834628 5%,
        #824528 30%,
        #ffdbb9 48%,
        #b55622 65%,
        #cb9a70 90%,
        #9a5e3d
      );
    }
    .kpb-gallery__item-caption {
      color: white;
    }
  }
  .residential__floorplan {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;

    .kpb-gallery__item:nth-child(1) {
      grid-column-end: span 3;
    }
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }
}
</style>
